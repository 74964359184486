export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Idosos',
    route: 'elderlys',
    icon: 'UserIcon',
  },
  {
    title: 'Responsáveis',
    route: 'responsibles',
    icon: 'UsersIcon',
  },
  {
    title: 'Financeiro',
    icon: 'DollarSignIcon',
    // tag: '1',
    // tagVariant: 'light-success',
    children: [
      {
        title: 'Planos',
        route: 'plans',
        icon: 'FeatherIcon',
      },
    ],
  },
  {
    title: 'Saúde',
    icon: 'ActivityIcon',
    // tag: '3',
    // tagVariant: 'light-danger',
    children: [
      // {
      //   title: 'Farmacias',
      //   route: 'pharmacies',
      //   icon: 'FlagIcon',
      // },
      {
        title: 'Medicamentos',
        route: 'medicaments',
        icon: 'ThermometerIcon',
      },
      {
        title: 'Forma Farmacêutica',
        route: 'amountTypes',
        icon: 'BarChart2Icon',
      },
    ],
  },

  {
    title: 'Interação',
    icon: 'SendIcon',
    // tag: '3',
    // tagVariant: 'light-primary',
    children: [
      {
        title: 'Mensagem',
        route: 'notification',
        icon: 'MessageCircleIcon',
      },
      {
        title: 'Banner',
        route: 'banner',
        icon: 'ImageIcon',
      },
      {
        title: 'Vídeos',
        route: 'videos',
        icon: 'FilmIcon',
      },
    ],
  },
  {
    title: 'Profissionais',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Profissionais',
        route: 'professionals',
        icon: 'UsersIcon',
      },
      {
        title: 'Sugestões',
        route: 'suggest',
        icon: 'UserPlusIcon',
      }
    ],
  },
  {
    title: 'Empresas',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Empresas',
        route: 'companies',
        icon: 'BriefcaseIcon',
      },
      {
        title: 'Categorias',
        route: 'companiesCategory',
        icon: 'ListIcon',
      }
    ],
  },
  // {
  //   title: 'Medicamentos',
  //   route: 'medicaments',
  //   icon: 'ThermometerIcon',
  // },
  // {
  //   title: 'Farmacias',
  //   route: 'pharmacies',
  //   icon: 'FlagIcon',
  // },
  // {
  //   title: 'Mensagem',
  //   route: 'notification',
  //   icon: 'MessageCircleIcon',
  // },
  // {
  //   title: 'Banner',
  //   route: 'banner',
  //   icon: 'ImageIcon',
  // },
  // {
  //   title: 'Vídeos',
  //   route: 'videos',
  //   icon: 'FilmIcon',
  // },
  {
    title: 'Termos',
    route: 'terms',
    icon: 'PenToolIcon',
  },
  {
    title: 'Política de privacidade',
    route: 'privacidade',
    icon: 'PenToolIcon',
  },
  {
    title: 'Categorias',
    route: 'categories',
    icon: 'ListIcon',
  },
  {
    title: 'Webmail',
    route: 'emails',
    icon: 'MailIcon',
  },
  {
    title: 'Firebase Analytcs',
    route: 'analytcs',
    icon: 'ActivityIcon',
  },

  

]
